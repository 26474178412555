<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import animateScrollTo from 'animated-scroll-to';
import { useElementSize } from '@vueuse/core';
import AlternateMarquee from '@@home/components/AlternateMarquee.vue';

const { t } = useI18n();
const bannerEle = ref<HTMLElement | null>(null);
const bannerBtns = [
  {
    name: t('home.banner.title1'),
    target: "_blank",
    style: { top: '60%', left: '38%' },
    href: 'https://competition.atomgit.com/'
  },
  {
    name: t('home.banner.title2'),
    target: "_blank",
    style: { top: '25%', left: '49%' },
    href: 'https://xiaoyuanxing.openatom.cn'
  },
  {
    name: t('home.banner.title3'),
    target: "_blank",
    style: { top: '25%', left: '88%' },
    href: 'https://atomgit.com'
  },
  {
    name: t('home.banner.title4'),
    target: "_blank",
    style: { top: '77%', left: '88%' },
    href: 'https://openatomcon.openatom.cn/'
  },
]
const { height } = useElementSize(bannerEle as any);
const seeMore = () => {
  animateScrollTo(height.value + 72, {
    cancelOnUserAction: false,
    speed: 1000,
    easing: t => (--t) * t * t + 1,
  });
}
const log = (name: string) => {
  const hmt = window._hmt || [];
  hmt.push(['_trackEvent', '首页Banner', 'click', name]);
}
</script>

<template>
  <section class="page-home-banner" ref="bannerEle">
    <!-- eslint-disable-next-line -->
    <video
      class="banner-video"
      src="https://res.oafimg.cn/-/9a5322e5c9d235a4/bg4.mp4"
      loop autoplay x5-video-player-type="h5" x5-playsinline muted webkit-playsinline playsinline preload="auto"
      poster="https://res.oafimg.cn/-/d7db4d805ec4f372/output.jpg"
    >
    </video>
    <!-- <video
      class="banner-video"
      src="https://res.oafimg.cn/-/e55406df4d6681d2/bg5.mp4"
      loop autoplay x5-video-player-type="h5" x5-playsinline muted webkit-playsinline playsinline preload="auto"
      poster="https://res.oafimg.cn/-/d7db4d805ec4f372/output.jpg"
    >
    </video> -->
    <div class="ml-148px mt-15.5vh <2xl:ml-80px <2xl:mt-12.5vh">
      <div class="title">
        HELLO，<br>OPENATOM！
      </div>
      <AlternateMarquee class="slogan" :speed="-100">
        {{ $t('home.slogan') }}
      </AlternateMarquee>
      <div class="see-more" @click="seeMore">
        <div class="see-more-text">{{ $t('home.more') }}</div>
        <div class="h-40px w-40px flex flex-shrink-0 items-center justify-center rounded-full bg-brand-primary">
          <div class="i-home-icons:arrow-down-1"></div>
        </div>
      </div>
    </div>

    <a
      v-for="(item, index) in bannerBtns"
      :key="index"
      :href="item.href"
      :target="item.target"
      :style="item.style"
      class="banner-btn"
      @click="log(item.name)"
    >
      <div class="h-40px w-40px flex flex-shrink-0 items-center justify-center rounded-full bg-brand-primary">
        <div class="i-home-icons:plus"></div>
      </div>
      <span class="banner-text">
        {{ item.name }}
      </span>
    </a>
  </section>
</template>

<style lang="scss" scoped>
.page-home-banner {
  position: relative;
  height: calc(100vh - 72px);
  overflow: hidden;

  .banner-video {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
    border: 0;
  }

  .title {
    color: #000;
    font-weight: 600;
    font-size: 68px;
    line-height: 95px;
    letter-spacing: 4px;
  }

  .slogan {
    max-width: 32vw;
    margin-top: 16px;
    color: #000;
    font-weight: 500;
    font-size: 46px;
    line-height: 65px;
  }

  .see-more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: fit-content;
    min-width: 200px;
    margin-top: 76px;
    padding: 8px;
    // margin-left: 5vw;
    color: #1180ff;
    font-weight: 400;
    font-size: 16px;
    background: rgba(17, 128, 255, 0.2);
    border-radius: 28px;
    cursor: pointer;
  }

  .see-more-text {
    flex: 1 auto;
    padding-right: 15px;
    padding-left: 20px;
    text-align: center;
  }

  .see-more-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .banner-btn {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: fit-content;
    padding: 8px;
    color: #1180ff;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    background-color: rgba(17, 128, 255, 0.2);
    border-radius: 28px;
    cursor: pointer;

    .banner-text {
      flex: 1 auto;
      padding-right: 20px;
      padding-left: 15px;
      // padding-right: 28px;
      // padding-left: 61px;
      // white-space: nowrap;
      text-align: center;
    }

    // &:hover .banner-text {
    //   opacity: 1;
    // }
  }

  @media (max-width: 1250px) {
    .banner-btn {
      display: none;
    }
  }

  @media (min-width: 1250px) and (max-width: 1450px) {
    .banner-btn:nth-last-child(1) {
      top: 85% !important;
      left: 83% !important;
    }

    .banner-btn:nth-last-child(2) {
      top: 40% !important;
      left: 84% !important;
    }

    .banner-btn:nth-last-child(3) {
      top: 26% !important;
      left: 44% !important;
    }
  }
}
</style>
